import React from 'react';
import { graphql, PageProps } from 'gatsby';

import populatePageElements from '../helpers/populate-page-elements';

import Layout from '../components/Layout';

const TermsOfUsePage = ({ data }: PageProps<Queries.TermsOfUsePageQuery>) => {
  const { elements, navElements, footerElements, termsElements } = populatePageElements(data);

  return (
    <Layout navElements={navElements} footerElements={footerElements} termsElements={termsElements}>
      {elements}
    </Layout>
  );
};
export default TermsOfUsePage;

export { Head } from '../components/Head';

export const query = graphql`
  query TermsOfUsePage {
    contentfulPage(pageName: { eq: "Terms of Use Page" }) {
      id
      components {
        __typename
        ... on ContentfulNavigation {
          id
          navLinks {
            title
            url
          }
          twitterLink
          instagramLink
          linkedInLink
        }
        ... on ContentfulTermsOfUse {
          id
          header
          title
          lastUpdated
          terms {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulFooter {
          id
          image {
            gatsbyImageData
          }
          footerLinks {
            title
            url
          }
          twitterLink
          instagramLink
          footerText {
            raw
          }
          linkedInLink
        }
        ... on ContentfulTerms {
          id
          title
          terms {
            childMarkdownRemark {
              html
            }
          }
          termsImage {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
